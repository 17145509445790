/**
 * A Service for handing file utilties
 */
function FileUtiltiesService() {
  /**
   * The new FileUtiltiesService Object
   *
   * @param {Object}
   * @access private
   */
  var service = new Object();

  /**
   * Check if the file exists?
   *
   * @param  {String} file The file to check
   * @return {Boolean}     Does it exist?
   * @access public
   */
  service.fileExists = function(file) {
    var deferred = $.Deferred();
    $.ajax({
      url: file,
      type:'HEAD',
      error: function() {
        deferred.resolve(false);
      },
      success: function(){
        deferred.resolve(true);
      }
    });
    return deferred.promise();
  };

  return service;
};
