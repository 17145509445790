/**
 * A Service for making requests to the API
 */
function PrayerApiService() {
  /**
   * The new LanguageService Object
   *
   * @param {Object}
   * @access private
   */
  var service = new Object();

  /**
   * Register the Consumer
   *
   * @return {String} The new API key
   * @access public
   */
  service.register = function() {
    return $.post('/prayer-api/register').then(
      function(response) {
        if (response.api_key) {
          return response.api_key;
        } else {
          return null;
        }
      }
    );
  };

  /**
   * Get the Prayer stats
   *
   * @param  {String}   apiKey    The Consumer API Key
   * @param  {String}   prayerId  The prayer id
   * @return {Object}             An object containing the prayer stats
   * @access public
   */
  service.prayerStats = function(apiKey, prayerId) {
    return $.ajax({
        type:'GET',
        beforeSend: function (request)
        {
            request.setRequestHeader('yop-api-key', apiKey);
        },
        url: '/prayer-api/prayers/'+prayerId
      }).then(function(response) {
        if (response.stats) {
          return response.stats;
        }
      },
    function(error) {
      console.error(error.responseText);
      return {};
    });
  };

  /**
   * Indicate you are praying
   *
   * @param  {String}   apiKey    The Consumer API Key
   * @param  {String}   prayerId  The prayer id
   * @return {Boolean}            Did it get set?
   * @access public
   */
  service.praying = function(apiKey, prayerId) {
    return $.ajax({
        type:'POST',
        beforeSend: function (request)
        {
            request.setRequestHeader('yop-api-key', apiKey);
        },
        url: '/prayer-api/prayers/'+prayerId+'/praying'
      }).then(function(response) {
        return response.success;
      },
    function(error) {
      console.error(error.responseText);
      return false;
    });
  };

  return service;
};
