/**
 * A Service for retrieving the Prayers
 * @param {LanguageService} The Language Service with set values
 */
function PrayerService(languageService) {

  if (typeof languageService === 'undefined') {
    throw new Error('You need to set the LanguageService when initializing the PrayerService.');
  }

  /**
   * The new PrayerService Object
   *
   * @param {Object}
   * @access private
   */
  var service = new Object();

  /**
   * Include the FileUtilitiesService
   *
   * @type {FileUtilitiesService}
   * @access private
   */
  var fileUtiltiesService = new FileUtiltiesService();

  /**
   * The URL for the prayer file built with substitution params
   *
   * @type {String}
   * @access private
   */
  var prayerFileUrl = '/data/content/{0}/prayers/{1}.json';

  /**
   * Did we use the fallback language?
   *
   * @type {Boolean}
   * @access public
   */
  service.useFallbackFile = false;

  /**
   * Generate the Prayer.id based on the given parameters
   *
   * @param  {Integer} month  The month of the prayer (1-12)
   * @param  {Integer} day    The day of the prayer
   *
   * @return {String}         The unique id for the prayer
   *
   *
   */
  service.generateId = function(month, day) {
    return pad(month) + '-' + pad(day);
  };

  /**
   * Get an id for Today's prayer
   *
   * @return {String}   Today's id
   * @access public
   */
  service.todaysId = function() {
    var today = new Date();
    var month = today.getMonth() + 1;
    return this.generateId(month, today.getDate());
  };

  /**
   * Get the prayer for today based on the User's browser date
   *
   * @return {Prayer} The Prayer Object
   * @access public
   */
  service.today = function() {
    var id = this.todaysId();
    return this.findById(id);
  };

  /**
   * Find the prayer by the given id
   *
   * @param  {String} id The unique prayer id
   * @return {Prayer}    The prayer object
   * @access public
   */
  service.findById = function(id) {
    var deferred = $.Deferred();
    var idArray = id.split('-');
    var month = parseInt(idArray[0]);
    var language = languageService.getLanguage();
    var mainFile = prayerFileUrl.format(language.directory, pad(month));
    if (languageService.hasFallback) {
      fileUtiltiesService.fileExists(mainFile).then(function(exists) {
        service.useFallbackFile = (exists) ? false : true;
        service.findByMonth(month).then(function(prayers) {
          var selected;
          $.each(prayers, function(index, prayer) {
            if (prayer.id === id) {
              selected = prayer;
            }
          });
          deferred.resolve(selected);
        }, function() {
          deferred.reject({});
        });
      });
    } else {
      service.findByMonth(month).then(function(prayers) {
        var selected;
        $.each(prayers, function(index, prayer) {
          if (prayer.id === id) {
            selected = prayer;
          }
        });
        deferred.resolve(selected);
      }, function() {
        deferred.reject({});
      });
    }
    return deferred.promise();
  };

  /**
   * Find the prayers for a specific month
   *
   * @param  {String} month Number representing the month
   * @return {Array}        An array of prayers
   * @access public
   */
  service.findByMonth = function(month) {
    var deferred = $.Deferred();
    var language = languageService.getLanguage();
    if (service.useFallbackFile) {
      language = languageService.getFallbackLanguage();
    }
    var file = prayerFileUrl.format(language.directory, pad(month));
    $.get(file).then(function(data) {
      var prayers = [];
      $.each(data.data, function(index, prayer) {
        prayer.language = language;
        prayers.push(new Prayer(prayer));
      });
      deferred.resolve(prayers);
    }, function() {
      deferred.reject([]);
    });
    return deferred.promise();
  };

  /**
   * Is the provided id valid?
   *
   * @param  {String} id The prayer id
   * @return {Boolean}   Is it valid?
   * @access public
   */
  service.isValidId = function(id) {
    var valid = /^([0-9]{2}\-{1}[0-9]{2})$/.test(id);
    var today = new Date();
    if (valid) {
      /**
       * verify the month and day
       */
      var idArray = id.split('-');
      var month = parseInt(idArray[0]);
      var day = parseInt(idArray[1]);
      var daysInMonth = new Date(today.getFullYear(), month, 0).getDate();
      if ((month > 12) || (day > daysInMonth)) {
        valid = false;
      }
    }
    return valid;
  };

  /**
   * Pad the given number with a leading 0
   *
   * @param  {Integer} number The number to pad
   *
   * @return {String}  The padded number
   *
   *
   */
  function pad(number) {
   return number<10 ? '0'+number : number;
  }

  /**
   * Return the service
   */
  return service;
}
/**
 * The Prayer Model
 */
function Prayer(data) {
  /**
   * A list of withlisted attributes
   *
   * @type {Array}
   * @access private
   */
  var whitelist = ['id', 'title', 'details', 'month', 'day', 'images', 'language'];

  /**
   * Private Methods
   */
  /**
   * Removes any attributes that are not whitelisted.
   *
   * @param  {Object} data The data to clean
   * @return {Object}      The cleaned data
   * @access private
   *
   *
   */
  function clean(data) {
    var cleanedData = $.extend(true, {}, data);
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        if (whitelist.indexOf(key) === -1) {
          delete cleanedData[key];
        }
      }
    }
    return cleanedData;
  }

  /**
   * Set default properties and functions
   */
  $.extend(true, this, {
    id:       '',
    title:    '',
    details:  '',
    month:    '',
    day:      '',
    images:   [],
    total_prayers: 0,
    consumer: {
      total_prayers: 0,
      last_prayer: null,
      throttled: false
    }
  });
  if (data.images.length > 0) {
    /**
     * Make sure it starts with a slash
     */
    for (var i = 0; i < data.images.length; i++) {
      if (data.images[i].substring(0,1) !== '/') {
        data.images[i] = '/'+data.images[i];
      }
    }
  }
  /**
   * Add the passed in data
   */
  $.extend(true, this, clean(data));
}
