/**
 * The Language Model
 */
function Language(data) {
  /**
   * A list of withlisted attributes
   *
   * @type {Array}
   * @access private
   */
  var whitelist = ['language', 'default', 'direction', 'string', 'region'];

  /**
   * Get the HTML language tag setting
   *
   * @param {Object}  self  This instance
   * @return {String}       The HTML tag for this language
   * @access private
   */
  function getHtmlTag(self) {
    return (self.region) ? self.language+'-'+self.region : self.language;
  }

  /**
   * Private Methods
   */
  /**
   * Removes any attributes that are not whitelisted.
   *
   * @param  {Object} data The data to clean
   * @return {Object}      The cleaned data
   * @access private
   *
   *
   */
  function clean(data) {
    var cleanedData = $.extend(true, {}, data);
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        if (whitelist.indexOf(key) === -1) {
          delete cleanedData[key];
        }
      }
    }
    return cleanedData;
  }

  /**
   * Set default properties and functions
   */
  $.extend(true, this, {
    language:     '',
    default:      false,
    direction:    'ltr',
    string:       '',
    region:       null
  });

  data.region = (data.region !== '') ? data.region : null;
  /**
   * Add the passed in data
   */
  $.extend(true, this, clean(data));
  /**
   * Set addition attributes
   */
  this.htmlTag = getHtmlTag(this);
  this.directory = this.htmlTag.toLowerCase();
}
