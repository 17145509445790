/**
 * Adds a .format() method to string that replaces string params
 * @example "{0} {1}".format("Fruit", "Cake") = "Fruit Cake"
 *
 * @return {String} A formatted string
 *
 * @author Johnathan Pulos <johnathan@missionaldigerati.org>
 */
String.prototype.format = function() {
    var formatted = this;
    for (var i = 0; i < arguments.length; i++) {
        var regexp = new RegExp('\\{'+i+'\\}', 'gi');
        formatted = formatted.replace(regexp, arguments[i]);
    }
    return formatted;
};
/**
 * Truncate a String by the set length
 *
 * @param  {Number}   length          How long you want it
 * @param  {Boolean}  useWordBoundary Do you want to bound it to a word
 * @return {String}                   The truncated String
 * @link http://stackoverflow.com/a/1199420
 * @access public
 */
String.prototype.truncate = function(length, useWordBoundary){
   var isTooLong = this.length > length;
   var str = isTooLong ? this.substr(0,length-1) : this;
   str = (useWordBoundary && isTooLong) ? str.substr(0,str.lastIndexOf(' ')) : str;
   return  isTooLong ? str + '&hellip;' : str;
};
/**
 * Get an object value using string dot notation to traverse object
 *
 * @param  {Object} current  The object to traverse
 * @param  {String} location The location to traverse to
 * @return {Mixed}           The value of the result location
 * @link http://stackoverflow.com/a/6491621
 * @access public
 */
Object.byString = function(current, location) {
    location = location.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    location = location.replace(/^\./, '');           // strip a leading dot
    var a = location.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in current) {
            current = current[k];
        } else {
            return;
        }
    }
    return current;
};
