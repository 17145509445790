/**
 * A Service for retrieving the Section and it's relevant pages
 *
 * @param {LanguageService} The Language Service with set values
 */
function SectionService(languageService) {

  if (typeof languageService === 'undefined') {
    throw new Error('You need to set the LanguageService when initializing the SectionService.');
  }

  /**
   * The new SectionService Object
   *
   * @param {Object}
   * @access private
   */
  var service = new Object();
  /**
   * Include the FileUtilitiesService
   *
   * @type {FileUtilitiesService}
   * @access private
   */
  var fileUtiltiesService = new FileUtiltiesService();
  /**
   * The URL for the section files built with substitution params
   *
   * @type {String}
   * @access private
   */
  var sectionFileUrl = '/data/content/{0}/pages/{1}.json';
  /**
   * Did we use the fallback language?
   *
   * @type {Boolean}
   * @access public
   */
  service.useFallbackFile = false;

  /**
   * Find the section content
   *
   * @param  {String} slug The unique slug for the section
   * @return {Section}     The section
   * @access public
   */
  service.find = function(slug) {
    var deferred = $.Deferred();
    if (languageService.hasFallback) {
      var language = languageService.getLanguage();
      var mainFile = sectionFileUrl.format(language.directory, slug);
      fileUtiltiesService.fileExists(mainFile).then(function(exists) {
        service.useFallbackFile = (exists) ? false : true;
        getSection(slug).then(function(section) {
          deferred.resolve(section);
        });
      });
    } else {
      getSection(slug).then(function(section) {
        deferred.resolve(section);
      });
    }
    return deferred.promise();
  };

  /**
   * Private Methods
   */
  function getSection(slug) {
    var deferred = $.Deferred();
    var language = languageService.getLanguage();
    if (service.useFallbackFile) {
      language = languageService.getFallbackLanguage();
    }
    var file = sectionFileUrl.format(language.directory, slug);
    $.get(file).then(function(data) {
      var section = data.data;
      section.language = language;
      var pages = section.pages;
      section.pages = [];
      $.each(pages, function(index, page) {
        page.language = language;
        section.pages.push(new Page(page));
      });
      section.pages.sort(sortByOrder);
      deferred.resolve(new Section(section));
    }, function() {
      deferred.reject({});
    });
    return deferred.promise();
  };
  /**
   * A sort function to sort by the order key
   *
   * @param  {Object} first  The first JSON object to compare
   * @param  {Object} second The second object to compare
   *
   * @return {Integer}       An integer to determine sort order
   *
   *
   */
  function sortByOrder(first, second) {
    return first.order - second.order;
  }

  return service;
};
/**
 * The Section Model
 */
function Section(data) {
  /**
   * A list of withlisted attributes
   *
   * @type {Array}
   * @access private
   */
  var whitelist = ['title', 'slug', 'pages', 'language'];
  /**
   * Private Methods
   */
  /**
   * Removes any attributes that are not whitelisted.
   *
   * @param  {Object} data The data to clean
   * @return {Object}      The cleaned data
   * @access private
   */
  function clean(data) {
    var cleanedData = $.extend(true, {}, data);
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        if (whitelist.indexOf(key) === -1) {
          delete cleanedData[key];
        }
      }
    }
    return cleanedData;
  }

  /**
   * Set default properties and functions
   */
  $.extend(true, this, {
    slug:   '',
    title:  '',
    pages:  []
  });
  /**
   * Add the passed in data
   */
  $.extend(true, this, clean(data));
};
/**
 * The Page Model
 */
function Page(data) {
  /**
   * A list of withlisted attributes
   *
   * @type {Array}
   * @access private
   */
  var whitelist = ['title', 'slug', 'content', 'page_type', 'order', 'images', 'email', 'subject', 'url', 'language'];
  /**
   * Private Methods
   */
  /**
   * Removes any attributes that are not whitelisted.
   *
   * @param  {Object} data The data to clean
   * @return {Object}      The cleaned data
   * @access private
   */
  function clean(data) {
    var cleanedData = $.extend(true, {}, data);
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        if (whitelist.indexOf(key) === -1) {
          delete cleanedData[key];
        }
      }
    }
    return cleanedData;
  }
  /**
   * Set default properties and functions
   */
  $.extend(true, this, {
    slug:       '',
    title:      '',
    content:    '',
    page_type:  'page',
    email:      '',
    subject:    '',
    order:      0,
    images:     [],
    url:        ''
  });
  /**
   * Add the passed in data
   */
  $.extend(true, this, clean(data));
};
